










import { Component, Vue } from 'vue-property-decorator'
import ContactForm from '@/components/ContactForm.vue'

@Component({
  data () {
    return {
      formSubmitted: false,
      headerTextLarge: 'Contact',
      headerTextSmall: 'Use the form below or email DurbanTech at admin@durbantech.com. We will get back to you as soon as we can!'
    }
  },
  components: {
    ContactForm
  },
  computed: {
    // Computed Values
  }
})
export default class Contact extends Vue {}
